import data from "../data/about-page-data.json";
import IconList from "../components/icon-list";
import Slider from "../components/slider";
import { SwiperSlide } from "swiper/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import SubTitle from "../components/SubTitle";
import seoData from "../data/seo-data.json";
import SEO from "../components/SEO";

const AboutPage = () => {
  const { iconListData, sliderData } = data;
  const aboutData = seoData.about;

  AOS.init({
    duration: 1000,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={aboutData.title}
        description={aboutData.description}
        ogTitle={aboutData.ogTitle}
        ogImage={aboutData.ogImage}
        keywords={seoData.keywords.join(", ")}
      />
      <section className="bg-radial-gradient-about bg-top pt-0 sm:pt-28 pb-0 2xl:pb-48 2xl:pt-60 md:pt-52 sm:pb-40">
        <div className="container" data-aos="fade-left">
          <div className="grid grid-cols-6 gap-4 items-center sm:h-auto h-[500px]">
            <div className="lg:col-span-4 col-span-5 ">
              <h1 className="text-primary text-7xl md:text-8xl md:leading-[100px] font-semibold">
                What makes <br className="sm:block hidden" />
                us a&nbsp;
                <span className="text-whiteSmoke">tygr</span>
              </h1>
            </div>
          </div>
          <span
            data-aos="fade-right"
            className="border-b-[4px] border-primary w-full sm:block hidden mt-8"
          ></span>
        </div>

        <div className="sm:bg-transparent bg-seaMoss pb-14 sm:pb-0 sm:pt-0 pt-12">
          <div className="container" data-aos="fade-left">
            <div className="grid grid-cols-7 gap-4 mt-10 2xl:mt-14 ">
              <div className="md:col-span-2 col-span-full">
                <h3 className="text-whiteSmoke text-4xl font-medium">
                  Our Story
                </h3>
              </div>
              <div className="md:col-span-5 col-span-full">
                <p className="text-whiteSmoke font-normal md:text-2xl text-sm md:leading-9 2xl:leading-normal 2xl:px-32">
                  TygrLabs, was founded by visionary entrepreneurs committed to
                  driving economic growth and highlighting untapped talent on a
                  global stage. The name “TYGR” embodies the spirit of
                  resilience, courage, and strength—core values that define our
                  company. We strive to be a global leader in business through a
                  relentless commitment to technology, innovation, and
                  excellence, empowering progress and shaping the future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-seaMoss pt-4 md:pt-28 md:pb-10 overflow-visible">
        <div className="container">
          <div className="grid grid-cols-12" data-aos="fade-up">
            <div className="rounded-15 md:col-span-12 col-span-full backdrop-blur mt-0 sm:-mt-48 py-8 px-10 bg-patina/65">
              <div className="flex justify-between items-center">
                <h5 data-aos="fade-up" className="text-whiteSmoke font-bold">
                  Mission
                </h5>
                <img
                  data-aos="fade-up"
                  src="/assets/icon/Union-circle.svg"
                  alt="ima"
                />
              </div>
              <p
                data-aos="fade-up"
                className="text-whiteSmoke md:text-3xl text-sm leading-6 md:leading-10 mt-14 2xl:mt-18 md:max-w-[59%]"
              >
                We are dedicated to providing innovative, tailored, and
                comprehensive support, ensuring our clients thrive in the global
                digital landscape
              </p>
            </div>
          </div>
          <div className="rounded-15 col-span-12 mt-5" data-aos="fade-up">
            <img
              src="/assets/images/group-img.svg"
              alt="img"
              className="w-full h-full object-cover rounded-[inherit]"
            />
          </div>
          <div className="grid grid-cols-12 gap-5 mt-5">
            <div className="md:col-span-7 col-span-full" data-aos="fade-up">
              <div className="rounded-15 p-10 bg-linear-gradient-about h-full">
                <div className="flex justify-between items-center">
                  <h5 className="text-whiteSmoke font-bold">Vision</h5>
                  <img
                    src="/assets/icon/eye.svg"
                    className="w-[40px]"
                    alt="ima"
                  />
                </div>
                <p className="text-whiteSmoke lg:text-3xl md:text-2xl text-sm lg:leading-10 mt-4 2xl:mt[150px] lg:mt-20 md:max-w-[80%]">
                  Becoming a global leader in transformative technology
                  solutions, TygrLabs aspires to redefine industry benchmarks,
                  by leveraging on our people, partners and performance. 
                </p>
              </div>
            </div>
            <div className="md:col-span-5 col-span-full" data-aos="fade-up">
              <div className="rounded-15">
                <img
                  className="rounded-[inherit] object-cover object-top w-full md:h-[434px]"
                  src="/assets/images/vision.png"
                  alt="ima"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-seaMoss pt-10 md:pt-8 pb-10 md:relative">
        <div className="container">
          <h2 className="pt-20 relative" data-aos="fade-up">
            Unique Solutions Tailored for&nbsp;
            <SubTitle
              className={"overflow-hidden"}
              innerClass={"left-0 bottom-0"}
              title={"You."}
              animationType={"fade-right"}
              animationOffset={"250"}
            />
            {/* <span className="border-b-4 border-primary"> You.</span> */}
          </h2>

          <div
            className="grid grid-cols-12 md:relative md:mt-40 mt-6"
            data-aos="fade-up"
          >
            <div className="md:h-[390px] h-52 col-span-full md:rounded-15 z-[1]">
              <img
                src="/assets/images/tygrLabs-landscape.png"
                className="w-full h-full object-cover object-center rounded-[inherit]"
                alt="ima"
              />
            </div>
          </div>
          <img
            className="absolute bg-animation hidden lg:block right-0 top-40"
            src="assets/shape/semi-circle.svg"
            alt="img"
          />
          <img
            className="absolute hidden bg-animation lg:block left-0 top-[800px]"
            src="assets/shape/180.svg"
            alt="img"
          />
          <div className="mt-20">
            <div className="bg-darkslategray/30 backdrop-blur md:p-28 rounded-15 md:relative">
              {iconListData &&
                iconListData.length > 0 &&
                iconListData.map((value, key) => {
                  return (
                    <div
                      className="md:mt-20 mt-10 first-of-type:mt-0"
                      key={key}
                    >
                      <IconList
                        heading={value.heading}
                        description={value.description}
                        icon={value.icon}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="mt-24">
            <h2
              data-aos="fade-up"
              className="md:px-10 md:leading-[80px] leading-8 relative overflow-hidden md:pb-0 pb-2"
            >
              We believe in making a difference,&nbsp;
              <br className="hidden md:block" />
              <span className={`relative overflow-hidden`}>
                giving back
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  className={`bg-primary h-1 rounded-15 w-full md:block absolute left-0 bottom-[-10px] hidden`}
                ></span>
              </span>
              , and ensuring that our success contributes to a better world for
              everyone. 
              <span
                className={`bg-primary h-1 rounded-15 w-[187px] md:hidden absolute left-1/2 -translate-x-1/2 bottom-0 block`}
              ></span>
            </h2>
          </div>
        </div>
      </section>
      <section className="bg-seaMoss md:pt-18 pt-4 pb-28">
        <Slider
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 2.7,
              spaceBetween: 10,
            },
          }}
          // data-aos="fade-up"
          speed={6000}
          loop={true}
          centeredSlides={true}
          sliderClass="about-swiper1 h-[293px] md:h-[400px] mt-20"
        >
          {sliderData &&
            sliderData.length > 0 &&
            sliderData.map((value, key) => {
              return (
                <SwiperSlide className="h-full" key={key}>
                  <img
                    className="w-full block h-full object-cover rounded-15 grayscale"
                    src={value.img}
                    alt="img"
                  />
                </SwiperSlide>
              );
            })}
        </Slider>
      </section>
      {/* <section className="bg-seaMoss md:pt-24 pb-52 md:relative md:overflow-y-hidden">
        <img
          className="absolute bg-animation hidden lg:block right-20 -bottom-60 2xl:hidden"
          src="assets/shape/360.svg"
          alt="img"
        />
        <div className="container md:px-20 transform scale-[0.9]">
          <div
            className="grid sm:grid-cols-7 grid-cols-1 rounded-15 overflow-hidden"
            data-aos="fade-up"
          >
            <div className="bg-secondary md:col-span-4 md:order-1 order-2 flex flex-col justify-between col-span-full lg:py-14 lg:pl-[57px] lg:pr-[65px] md:p-8 p-5 md:rounded-none rounded-15">
              <p className="md:text-2xl lg:text-3xl 2xl:text-4xl text-xl font-normal text-white/80 !leading-normal ">
                “Your services made that hectic day an easy and enjoyable
                experience and I’m really grateful. Huge thanks to you and your
                team”
              </p>

              <div className="md:w-[165px] w-[120px] mt-28 md:mt-16 lg:mt-0">
                <img
                  src="assets/images/maryCate.svg"
                  alt=""
                  className="w-full object-contain object-left"
                />
              </div>
            </div>
            <div className="md:mb-0 mb-3 md:col-span-3 md:order-2 order-1 col-span-full md:relative md:h-full h-[195px] xs:h-[450px] sm:h-auto">
              <img
                src="assets/images/testimonial.svg"
                className="h-full w-full object-cover md:object-center object-center-top md:rounded-none rounded-15"
                alt="img"
              />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};
export default AboutPage;

import React from "react";
import "./index.css";
import CareerPage from "./pages/career";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import JobApply from "./pages/apply";
import Layout from "./layout";
import { Privacy } from "./pages/privacy";
import { Terms } from "./pages/terms";
import PageNotFound from "./pages/PageNotFound";
import { HelmetProvider } from "react-helmet-async";
import Services from "./pages/Services";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Layout is404={false} />}>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="career" element={<CareerPage />}></Route>
          <Route path="about" element={<AboutPage />}></Route>
          <Route path="services" element={<Services />}></Route>
          <Route path="apply" element={<JobApply />}></Route>
          <Route path="privacy" element={<Privacy />}></Route>
          <Route path="terms-and-service" element={<Terms />}></Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="*" element={<Layout is404={true} />}>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;

import Button from "../components/button";
import CounterCard from "../components/counter-card";
import data from "../data/home-page-data.json";
import Slider from "../components/slider";
import { SwiperSlide } from "swiper/react";
import ContactForm from "../components/form-template";
import "swiper/css/effect-fade";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import SubTitle from "../components/SubTitle";
import SEO from "../components/SEO";
import seoData from "../data/seo-data.json";

const HomePage = () => {
  const { sliderData, sliderData2, sliderData3 } = data;
  const homeData = seoData.home;
  AOS.init({
    duration: 1000,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const contactRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const contactId = searchParams.get("to");
    const contactElement = document.getElementById(contactId);

    if (contactId === "contact" && contactElement) {
      // Calculate the position of the element
      const rect = contactElement.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const elementPosition = rect.top + scrollTop;

      // Determine the offset based on the window's width
      const offset =
        window.innerWidth <= 768
          ? window.innerHeight * -0.002
          : window.innerWidth * 0.1;

      // Scroll to the position offset pixels before the element
      window.scrollTo({ top: elementPosition - offset, behavior: "smooth" });
    } else {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.search]);

  return (
    <>
      <SEO
        title={homeData.title}
        description={homeData.description}
        ogTitle={homeData.ogTitle}
        ogImage={homeData.ogImage}
        keywords={seoData.keywords.join(", ")}
      />
      <section className="bg-radial-gradient-home md:relative h-screen">
        <img
          className="w-[800px] bg-animation hidden lg:block absolute -top-96 right-0 circle-shape-career"
          src="assets/shape/full-circle.svg"
          alt="img"
        />
        <img
          className="hidden w-[800px] bg-animation top-[450px] left-20 lg:block absolute circle-shape-career"
          src="assets/shape/half-circle1.svg"
          alt="img"
        />
        <div className="container md:relative h-full">
          <div className="grid grid-cols-4 gap-4 items-center h-full">
            <div className="col-span-full pt-12" data-aos="fade-down">
              <h1 className="text-Carnation text-3xl xs:text-4xl sm:text-5xl md:text-6xl lg:text-8xl lg:leading-tight font-semibold hero-slider">
                Future of&nbsp;
                <br />
                <Slider
                  direction="vertical"
                  spaceBetween={30}
                  autoplay={true}
                  loop={true}
                  speed={450}
                  sliderClass="text-slider h-[40px] xs:h-[44px] sm:h-[53px] md:h-[73px] lg:h-[128px] overflow-hidden"
                >
                  {sliderData3 &&
                    sliderData3.length > 0 &&
                    sliderData3.map((value, key) => {
                      return (
                        <SwiperSlide key={key}>
                          <span className="text-alabaster relative before:absolute before:content-[''] xs:before:bottom-[-4px] before:bottom-[-4px] before:w-full before:h-[3px] xs:before:h-[4px] before:left-0 before:bg-white">
                            <span className="animated-text text-3xl xs:text-4xl sm:text-5xl md:text-6xl lg:text-8xl whitespace-nowrap">
                              {value.text}&nbsp;
                            </span>
                            <br />
                          </span>
                        </SwiperSlide>
                      );
                    })}
                </Slider>
                Starts Here.
              </h1>
              {/* <Button
                type="arrow-btn"
                customClass="mt-36 md:mx-0 mx-auto mb-10"
              >
                Know More
              </Button> */}
              <div className="md:text-left text-center">
                <Button
                  type="link-btn"
                  arrow={true}
                  customClass="!transition-all inline-flex items-center group inline-block mt-36 md:mx-0 mx-auto mb-10"
                  url="/about"
                >
                  Know More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-whiteSmoke pt-20 pb-10 md:pt-40 md:pb-20">
        <div className="container" data-aos="fade-up">
          <p className="text-secondary text-xl md:text-2xl md:leading-8 leading-6 font-normal text-center max-w-4xl mx-auto">
            TygrLabs provides companies all over the world with customer
            support, finance, HR support, technical support, product design,
            product development and 360 marketing solutions. We specialize in
            travel technology. Our expertise streamlines non-core functions,
            empowering businesses to focus on their core activities in a
            cost-effective and efficient way.  
          </p>
          <div className="flex flex-col lg:flex-row gap-8 justify-center items-center mt-14 md:mt-28">
            <CounterCard
              number="3"
              suffix="x"
              description="Revenue Growth"
              cardColor="dark"
            />
            <CounterCard
              number="20"
              suffix="+"
              description="Global Clients"
              cardColor="white"
            />
          </div>
          <div className="text-center mt-6 md:mt-8">
            <CounterCard
              number="50"
              suffix="+"
              description="Successful Campaigns"
              cardColor="primary"
            />
          </div>
        </div>
      </section>
      <section className="bg-whiteSmoke py-10 md:py-20">
        <div data-aos="fade-up">
          <div className="container" data-aos="fade-up">
            <h3 className="text-dark text-xl md:text-2xl font-medium text-center">
              Building&nbsp;
              <span className="text-primary">success&nbsp;</span>
              hand-in-hand
              <br />
              with our amazing&nbsp;
              <span className="text-primary">clients.</span>
            </h3>
          </div>
          {/* <Slider
            slidesPerView={5}
            spaceBetween={10}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={6000}
            breakpoints={{
              320: {
                slidesPerView: 3,
                speed: 2000,
                delay: 0,
                loop: true,
                spaceBetween: 20,
              },
              575: {
                slidesPerView: 4,
                speed: 3000,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 5,
                speed: 5000,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
            loop={true}
            centeredSlides={true}
            sliderClass="homepage-swiper1 h-[60px] mt-10 md:mt-28"
          >
            {sliderData &&
              sliderData.length > 0 &&
              sliderData.map((value, key) => {
                return (
                  <SwiperSlide key={key}>
                    <img
                      className="w-full h-full object-contain"
                      src={value.img}
                      alt="img"
                    />
                  </SwiperSlide>
                );
              })}
          </Slider> */}
        </div>
      </section>
      <section className="bg-diamond-gradient-home py-20 md:relative">
        <div data-aos="fade-up">
          <img
            className="absolute lg:block bg-animation hidden right-0 top-0"
            src="assets/shape/circleT.svg"
            alt="img"
          />
          <img
            className="absolute lg:block bg-animation hidden left-0 bottom-0"
            src="assets/shape/circleb.svg"
            alt="img"
          />
          <div className="container md:relative">
            <img
              className="absolute lg:block bg-animation hidden lg:left-[-11%]  bottom-[-13%]"
              src="assets/shape/lightL.svg"
              alt="img"
            />
            <img
              className="absolute lg:block bg-animation hidden lg:right-[43%] top-0"
              src="assets/shape/lightR.svg"
              alt="img"
            />
            <div data-aos="fade-up">
              <h3 className="text-whiteSmoke text-2xl lg:text-4xl font-medium text-center">
                How we Bring Ideas to Life 
              </h3>
            </div>

            <Slider
              slidesPerView={1}
              spaceBetween={30}
              autoHeight={false}
              mousewheel={{
                enabled: true,
                releaseOnEdges: true,
                sensitivity: 400,
              }}
              effect={"fade"}
              pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="!w-16 !h-1.5 !bg-whiteSmoke/20 !rounded-none transition-all ease-linear duration-400 ${className}"></span>`;
                },
              }}
              sliderClass="homepage-swiper2 !pb-10"
            >
              {sliderData2 &&
                sliderData2.length > 0 &&
                sliderData2.map((value, key) => {
                  return (
                    <SwiperSlide className="h-full" key={key}>
                      <div className="grid grid-cols-6 gap-4 backdrop-blur bg-whiteSmoke/30 mt-20 rounded-27  overflow-hidden">
                        <div className="col-span-full md:col-span-3 p-4 lg:p-20 md:p-10 flex flex-col justify-between">
                          <p className="md:font-semibold font-bold text-center md:text-start font-Outfit text-4xl md:text-6xl lg:text-8xl text-whiteSmoke">
                            {value.heading}
                          </p>
                          <p className="lg:text-2xl md:text-lg text-xl text-center md:text-start font-medium text-whiteSmoke md:mt-0 mt-10">
                            {value.description}
                          </p>
                        </div>
                        <div className="col-span-full md:col-span-3 px-4 md:px-0 pb-4 md:pb-0 rounded-[inherit] md:rounded-r-[inherit] md:rounded-l-none md:h-[350px] lg:h-[560px] h-60">
                          <img
                            className="w-full h-full object-cover rounded-[inherit] md:rounded-r-[inherit] md:rounded-l-none"
                            src={value.img}
                            alt={value.description}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Slider>
          </div>
        </div>
      </section>
      <section className="bg-whiteSmoke md:py-24 py-2">
        <div className="container">
          <div className="grid grid-cols-10 gap-4 mt-20 lg:h-[382px]">
            <div className="md:col-span-6 hidden md:block col-span-full relative rounded-42 lg:p-14 p-7 overflow-hidden">
              <div
                className="bg-white absolute top-0 left-0 w-full h-full z-1"
                data-aos="fade-top"
                data-aos-offset="450"
                data-aos-duration="300"
              ></div>
              <div
                className="flex justify-between p-0 m-0 relative z-10"
                data-aos="fade-right"
              >
                <h2 className="md:text-6xl text-3xl 2xl:text-7xl text-primary font-semibold">
                  Find out <br />
                  <span data-aos="fade-left" data-aos-offset="400">
                    T
                  </span>
                  <span>y</span>
                  <span data-aos="fade-left" data-aos-offset="400">
                    g
                  </span>
                  <span data-aos="fade-left" data-aos-offset="400">
                    r
                  </span>
                </h2>
                <img
                  className="lg:w-[222px] sm:w-[118px] w-[100px] "
                  src="assets/images/home-Y.png"
                  alt="Find out WHY"
                />
              </div>
            </div>
            <div
              className="md:col-span-4 col-span-full px-8 hidden md:flex flex-col justify-between"
              data-aos="fade-left"
              data-aos-offset="450"
            >
              <h3 className="font-semibold lg:text-5xl text-xl relative overflow-hidden text-secondary hidden md:block">
                <span className="relative overflow-hidden">
                  <SubTitle
                    className={"inline-block mb-1"}
                    innerClass={"lg:top-[52px]"}
                    title={"Client"}
                    animationType={"fade-right"}
                    animationOffset={"450"}
                  />
                  {/* <span className="border-b-[4px] border-primary">Client</span> */}
                  <img
                    className="mt-2 pl-2 lg:h-10 h-6 inline-block align-middle"
                    src="assets/images/client.svg"
                    alt="img"
                  />
                </span>
                <br />
                Satisfaction is our North Star
              </h3>
              <img
                className="mt-2 m-auto h-10 w-12 md:hidden block"
                src="assets/images/client.svg"
                alt="img"
              />
              <h3 className="font-medium mt-4 text-2xl text-center leading-9 font-Outfit text-secondary md:hidden block">
                Client Satisfaction is our North Star
              </h3>
              <p className="text-lg mt-10 text-center md:text-start md-mt-0 font-normal text-secondary/50">
                We provide an end-to-end product development approach, ensuring
                compatibility and minimizing risks. From idea inception to
                implementation, TygrLabs handles every aspect of digital product
                development.
              </p>
            </div>
            <div
              className="md:col-span-4 col-span-full px-8 md:hidden flex flex-col justify-between"
              data-aos="fade-left"
            >
              <h3 className="font-semibold lg:text-5xl text-xl relative overflow-hidden text-secondary hidden md:block">
                <span className="relative overflow-hidden">
                  <SubTitle
                    className={"inline-block mb-1"}
                    innerClass={"lg:top-[52px]"}
                    title={"Client"}
                    animationType={"fade-right"}
                  />
                  {/* <span className="border-b-[4px] border-primary">Client</span> */}
                  <img
                    className="mt-2 pl-2 lg:h-10 h-6 inline-block align-middle"
                    src="assets/images/client.svg"
                    alt="img"
                  />
                </span>
                <br />
                Satisfaction is our North Star
              </h3>
              <img
                className="mt-2 m-auto h-10 w-12 md:hidden block"
                src="assets/images/client.svg"
                alt="img"
              />
              <h3 className="font-medium mt-4 text-2xl text-center leading-9 font-Outfit text-secondary md:hidden block">
                Client Satisfaction is our North Star
              </h3>
              <p className="text-lg mt-10 text-center md:text-start md-mt-0 font-normal text-secondary/50">
                We provide an end-to-end product development approach, ensuring
                compatibility and minimizing risks. From idea inception to
                implementation, TygrLabs handles every aspect of digital product
                development.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-4 mt-14 md:mt-24 lg:h-[382px]">
            <div
              className="md:col-span-4 order-2 md:order-1 col-span-full flex flex-col justify-between px-8"
              data-aos="fade-right"
            >
              <h3 className="lg:text-5xl lg:leading-[52px] text-xl relative overflow-hidden text-secondary font-semibold hidden md:block">
                <SubTitle
                  innerClass={"lg:top-[60px] md:top-[30px]"}
                  title={"Tech"}
                  animationType={"fade-right"}
                  animationOffset={"250"}
                />
                &nbsp;
                {/* <span className="border-b-[4px] border-primary">
                  Tech&nbsp;
                </span> */}
                it to the
                <br />
                <span className="flex items-center mt-2">
                  <span> Next level</span>
                  <img
                    className="mt-1 pl-2 lg:h-8 h-5"
                    src="assets/images/tech.svg"
                    alt="img"
                  />
                </span>
              </h3>
              <img
                className="mt-12 m-auto h-10 w-12 md:hidden block"
                src="assets/images/tech.svg"
                alt="img"
              />
              <h3 className="font-medium mt-4 text-2xl text-center leading-9 font-Outfit text-secondary md:hidden block">
                Tech it to the Next level
              </h3>
              <p className="text-lg mt-10 text-center md:text-start md-mt-0 font-normal text-secondary/50">
                At TygrLabs, we stay up-to-date with global tools and
                technologies to deliver top-quality products and services for
                you to tackle future challenges head-on. 
              </p>
            </div>
            <div
              className="md:col-span-6 order-1 hidden md:block md:order-2  col-span-full rounded-42 bg-white/50 lg:py-12 lg:px-20 py-8 px-10 h-full"
              data-aos="fade-left"
            >
              <div className="grid grid-cols-10 gap-4 md:gap-2 lg:py-20 lg:px-28 p-8 rounded-42 bg-white h-full">
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/react.svg"
                  alt="React"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/icon-img4.svg"
                  alt="img"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/icon-img3.svg"
                  alt="angular"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/node.svg"
                  alt="nodejs"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/icon-img2.svg"
                  alt="flutter"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/html.svg"
                  alt="html"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/vew.svg"
                  alt="vuejs"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/figma.svg"
                  alt="figma"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/icon-img.svg"
                  alt="azure"
                />
                <img
                  className="col-span-2 block h-[90%] w-[90%] object-contain"
                  src="assets/images/Union.svg"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-10 gap-4 mt-14 md:mt-20 2xl:mt-32 lg:h-[382px]">
            <div
              className="md:col-span-6 hidden md:block col-span-full rounded-42 h-full"
              data-aos="fade-right"
            >
              <img
                className="w-full h-full object-cover rounded-[inherit]"
                src="assets/images/beyond1.svg"
                alt="Beyond One Size Fits All"
              />
            </div>
            <div
              className="md:col-span-4 col-span-full md:px-6 px-5 h-full flex flex-col justify-between"
              data-aos="fade-left"
            >
              <h3 className="lg:text-5xl lg:leading-[52px] md:leading-none text-xl relative overflow-hidden text-secondary font-semibold hidden md:block">
                <SubTitle
                  innerClass={"lg:top-[65px] md:top-[30px]"}
                  title={"Beyond "}
                  animationType={"fade-right"}
                  animationOffset={"250"}
                />
                &nbsp;
                {/* <span className="border-b-[4px] border-primary">
                  Beyond&nbsp;
                </span> */}
                One
                <br />
                <span className="flex mt-4">
                  <span>Size Fits All</span>
                  <img
                    className="lg:mt-1 pl-2 lg:h-10 h-5"
                    src="assets/images/beyond.svg"
                    alt="img"
                  />
                </span>
              </h3>
              <img
                className="mt-12 m-auto h-10 w-12 md:hidden block"
                src="assets/images/beyond.svg"
                alt="img"
              />
              <h3 className="font-medium mt-4 text-2xl text-center leading-9 font-Outfit text-secondary md:hidden block">
                Beyond One Size Fits All
              </h3>
              <p className="text-lg mt-10 text-center md:text-start md-mt-0 font-normal text-secondary/50">
                Offering a personalized and tailored consultation approach to
                all our clients. Emphasizing TygrLabs' commitment to
                understanding each client's unique needs and goals and crafting
                customized solutions that precisely align with their vision.   
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="contact"
        ref={contactRef}
        className="bg-whiteSmoke px-5 sm:py-20 sm:h-auto h-screen sm:block flex flex-col justify-center"
      >
        <div className="container">
          <div data-aos="fade-up">
            <p className="lg:text-4xl text-xl font-semibold text-secondary">
              Lets team up.
            </p>
            <p className="lg:text-8xl text-3xl leading-tight font-medium text-primary  mb-6">
              hello
              <span className="text-secondary">@tygrlabs.co</span>
            </p>
          </div>
          <div data-aos="fade-up">
            <ContactForm
              cv={false}
              cvReq={false}
              btnColor="!bg-primary"
              bgColor="bg-transparent border-solid border-4 border-slate-300 outline-0"
              placeholderEmail="Enter your mail (Required)"
              placeholderText="Let’s talk! (Required)"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
